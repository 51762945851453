import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [6],
		"/app-content/[...acpath]": [~7],
		"/auth/login": [8],
		"/auth/protected/choose-username": [~9],
		"/auth/protected/logout": [~10],
		"/community": [11],
		"/contact": [12],
		"/fairminds": [~13],
		"/fairminds/protected/new": [35],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]": [~14,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/current": [~29,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/protected/all-editions": [~30,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/protected/tools": [~31,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/published-editions": [~32,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/revisions/[itemId=integer]": [~33,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/revisions/[itemId=integer]/[revisionId=integer]": [~34,[2]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]": [~15,[2,3]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/-/decision-factors": [16,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/-/summary": [~17,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/-/your-decision": [18,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/-/your-priorities": [19,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/move": [~28,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[items=itemsvals]": [~26,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]": [~20,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[items=itemsvals]/new": [~27,[2,3,4]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]/[baseItemSlug]": [21,[2,3,4,5]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]/[baseItemSlug]/delete": [22,[2,3,4,5]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]/[baseItemSlug]/detail/app-content/[...acpath]": [~24,[2,3,4,5]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]/[baseItemSlug]/detail/[detailItemId=integer]": [~23,[2,3,4,5]],
		"/fairminds/[fairmindId=integer]/[fairmindSlug]/[editionId=integer]/[access=accessvals]/[baseItemId=integer]/[baseItemSlug]/edit": [~25,[2,3,4]],
		"/feedback-group/april-2024": [~36],
		"/protected/admin-tools": [~37],
		"/protected/dashboard": [~38],
		"/users": [~39],
		"/users/[username]": [~40]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';