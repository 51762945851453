import {
	handleErrorWithSentry,
	init as sentryInit,
	replayIntegration
} from '@sentry/sveltekit';

sentryInit({
	dsn: 'https://d908922d2f46873dbc558ca59c2ea687@o4507092836876288.ingest.us.sentry.io/4507092844609536',
	tracesSampleRate: 1,
	environment: process.env.NODE_ENV,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
