import type { ParamMatcher } from '@sveltejs/kit';
import { logger } from '$lib/logger';

export const match: ParamMatcher = (param) => {
	const shouldBeAnInteger = Number(param);
	const isAnInteger = Number.isInteger(shouldBeAnInteger);
	if (isAnInteger) {
		return true;
	} else {
		logger.error(`Could not cast URL param value “${param}” to an integer`);
		return false;
	}
};
